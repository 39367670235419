@import "../bootstrap-overrides";

.Title {
  @extend .display-4, .my-4, .text-center;
}

.Form {
  @extend .mx-auto, .py-4;

  max-width: 14em;
}
